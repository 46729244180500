import React, { useMemo, Fragment, useEffect } from 'react'
import { Route, Switch, Redirect, HashRouter as Router, useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { StaticCan, Can } from '@Components/can'

import Box from '@mui/material/Box'
import Hidden from '@mui/material/Hidden'

import { Toolbar } from '@Components/toolbar'
import { MobileAppbar } from '@Components/mobiletoolbar'

import { Home } from '../screens/Home'

import { Users } from '../screens/users/Users'

import { SellerSearch } from '../screens/sellers/SellerSearch'
import { CreditorsSearch } from '../screens/creditors/CreditorsSearch'
import { CreditorContainer } from '../screens/creditors/CreditorContainer'
import { CreditorNew } from '../screens/creditors/CreditorNew'
import { MarketplaceSearch } from '../screens/marketplaces/MarketplaceSearch'

import { SellerContainer } from '../screens/sellers/SellerEdit/SellerContainer'
import { SellerNew } from '../screens/sellers/SellerNew'
import { MarketplaceContainer } from '../screens/marketplaces/marketplaceEdit/MarketplaceContainer'
import { ConditionsEdit } from '../screens/marketplaces/marketplaceEdit/conditions/ConditionsEdit'
import { ConditionsNewForm } from '../screens/marketplaces/marketplaceEdit/conditions/ConditionsNewForm'
import { MarketplaceProfile } from '../screens/marketplaces/marketplaceEdit/MarketplaceProfile'

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, gql } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { Setup } from '../screens/users/Setup'

import { RestrictAccess } from '@Components/RestrictAccess'

import { commonRoles } from '../utils/Roles'
import { RootState } from '../store/Store'
import { mountAuthHeaders } from 'src/utils/mountAuthHeaders'
StaticCan.ROLES = commonRoles

const bffHttpLink = createHttpLink({
  uri: `${String(process.env.REACT_APP_API_ADDRESS)}/crm-core-bff/query`,
})

const Routes: React.FC = () => {
  const user = useSelector((state: RootState) => state.user)
  const authLink = setContext((_, { headers }) => {
    return {
      headers: mountAuthHeaders(user),
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(bffHttpLink),
    cache: new InMemoryCache(),
  })

  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route path={['/users/setup/:id', '/password/:id']} exact component={Setup} />
          <Route path='/' component={() => <RealRoutes />} />
        </Switch>
      </Router>
    </ApolloProvider>
  )
}

const RealRoutes: React.FC = () => {
  const { i18n, language } = useSelector((state: RootState) => state.translation)
  const { mainIsOpen } = useSelector((state: RootState) => state.toolbar)
  const { roles: userRoles, merchant, marketplace } = useSelector((state: RootState) => state.user)

  const location = useLocation()
  const history = useHistory()

  const fallback = () => <Redirect to='/restrictAccess' />

  useEffect(() => {
    if (merchant && i18n) {
      history.push(`/sellers/edit/${merchant}/home`)
    }
  }, [merchant, i18n])

  useEffect(() => {
    if (marketplace && i18n) {
      history.push(`/marketplace`)
    }
  }, [marketplace, i18n])

  return (
    <Fragment>
      <div>
        <Hidden smDown>
          {useMemo(
            () => (
              <Toolbar />
            ),
            [mainIsOpen, location.pathname],
          )}
        </Hidden>
      </div>
      {useMemo(
        () =>
          i18n && (
            <Box
              width={1}
              sx={{
                height: 'inherit',
              }}
            >
              <Hidden smUp>
                <MobileAppbar />
              </Hidden>
              <Switch>
                <Route path='/restrictAccess' exact component={RestrictAccess} />
                <Route path='/' exact component={Home} />
                <Route
                  path='/users'
                  exact
                  component={() => (
                    <Can userRoles={userRoles} fallback={fallback}>
                      <Users />
                    </Can>
                  )}
                />

                <Route
                  path='/sellers'
                  exact
                  component={() => (
                    <Can roles={commonRoles} userRoles={userRoles} fallback={fallback}>
                      <SellerSearch />
                    </Can>
                  )}
                />

                <Route
                  path='/sellers/edit/:id/:page'
                  exact
                  component={() => (
                    <Can roles={commonRoles} userRoles={userRoles} fallback={fallback}>
                      <SellerContainer />
                    </Can>
                  )}
                />

                <Route
                  path='/sellers/new'
                  exact
                  component={() => (
                    <Can roles={commonRoles} userRoles={userRoles} fallback={fallback}>
                      <SellerNew />
                    </Can>
                  )}
                />

                <Route
                  path='/creditors'
                  exact
                  component={() => (
                    <Can userRoles={userRoles} fallback={fallback}>
                      <CreditorsSearch />
                    </Can>
                  )}
                />

                <Route
                  path='/creditors/:id/edit/:page'
                  exact
                  component={() => (
                    <Can userRoles={userRoles} fallback={fallback}>
                      <CreditorContainer />
                    </Can>
                  )}
                />

                <Route
                  path='/creditors/new'
                  exact
                  component={() => (
                    <Can userRoles={userRoles} fallback={fallback}>
                      <CreditorNew />
                    </Can>
                  )}
                />

                <Route
                  path='/marketplace'
                  exact
                  component={() => (
                    <Can roles={commonRoles} userRoles={userRoles} fallback={fallback}>
                      <MarketplaceSearch />
                    </Can>
                  )}
                />

                <Route
                  path='/marketplace/edit/:id/:page'
                  exact
                  component={() => (
                    <Can userRoles={userRoles} fallback={fallback}>
                      <MarketplaceContainer />
                    </Can>
                  )}
                />

                <Route
                  path='/marketplace/new'
                  exact
                  component={() => (
                    <Can userRoles={userRoles} fallback={fallback}>
                      <MarketplaceProfile method={'new'} />
                    </Can>
                  )}
                />

                <Route
                  path='/marketplace/edit/:id/conditions/TypeOperations/:idOperation'
                  exact
                  component={() => (
                    <Can userRoles={userRoles} fallback={fallback}>
                      <ConditionsEdit />
                    </Can>
                  )}
                />

                <Route
                  path='/marketplace/edit/:id/conditions/:titlePage/TypeOperations/:idOperation?'
                  exact
                  component={() => (
                    <Can userRoles={userRoles} fallback={fallback}>
                      <ConditionsNewForm />
                    </Can>
                  )}
                />
              </Switch>
            </Box>
          ),
        [location.pathname, userRoles, language, i18n],
      )}
    </Fragment>
  )
}

export default Routes
