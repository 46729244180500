import React, { useEffect, Fragment, useState, memo } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useQuery, gql } from '@apollo/client'
import { useParams, useHistory } from 'react-router-dom'
import { t } from '@lingui/macro'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { FormControl } from '@Components/formControl'
import TextField from '@Components/textField'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { CurrencyInput } from '@Components/currency-input'
import { PercentInput } from '@Components/percentageInput'
import { MarketplaceConditionsActions } from '../../../../store'
import { RootState } from '../../../../store/Store'
import AppBar from '@Components/appBar'
import PageContent from '@Components/pageContent'
import styled from '@emotion/styled'
import { useSnackbar } from 'notistack'
import { ConditionFormDataType, ConditionType, ConditionsNewFormPropsType } from './interfaces'
import CircularProgress from '@mui/material/CircularProgress'
import Loading, { BackdropLoading } from '@Components/loading'
import { IOperationTypes } from 'src/resources/transactions'

const {
  setOperations,
  getMerchantsCreditorList,
  closeSnackbar,
  clearListAllOperationsType,
  clearMerchantsCreditorList,
} = MarketplaceConditionsActions

const GET_PROCESSING_CODES = gql`
  query search_processing_codes {
    search_processing_codes {
      pages
      current_page
      items {
        processing_code
        balance_impact
        description
        balance_impact_id
      }
    }
  }
`

export const ContainerStyled = styled(Box)`
  height: 300px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  align-items: normal;
  flex-direction: row;
  justify-content: center;
`

export const ContentCheck = ({ listFiltered, handleChange, listChecks }: ConditionsNewFormPropsType) => {
  if (listFiltered) {
    return listFiltered.map((obj, i) => {
      return (
        <Box key={`${i}-itemCheck`} data-testid='mktCondiciones-check' sx={{ flexBasis: '50%' }}>
          <FormControlLabel
            control={
              <Checkbox
                name='operation_type_id'
                value={obj?.processing_code}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const list_checks = listChecks
                  if (e.target.checked) {
                    list_checks.push(e.target.value)
                  } else {
                    list_checks.splice(list_checks.indexOf(e.target.value), 1)
                  }

                  handleChange({
                    target: {
                      name: 'checks_operations',
                      value: list_checks,
                    },
                  })
                }}
                data-testid='check-webpos'
              />
            }
            label={`${obj?.processing_code} - ${obj?.description}`}
          />
        </Box>
      )
    })
  }
}

const isEqual = (prev, next) => prev.listChecks === next.listChecks && !!prev.listFiltered?.length
const ContentCheckMemo = memo(ContentCheck, isEqual)

const ConditionForm = (props: ConditionFormDataType) => {
  const {
    initialFormState,
    merchantsCreditorList,
    handleSubmit,
    listAllOperationsType,
    list_checks,
    idOperation,
    operationsTypesLoading,
    setSelectCreditor,
    loading,
  } = props
  const [listFiltered, setListFiltered] = useState<any[]>([])
  const { i18n, language } = useSelector((state: RootState) => state.translation)

  useEffect(() => {
    if (listAllOperationsType) {
      setListFiltered([...listAllOperationsType])
    }
  }, [listAllOperationsType])

  const filteredCreditor = (idCreditor: string) => {
    let filteredCreditorsItems: string[] = []
    let filteredCheckItems: IOperationTypes[] = []

    merchantsCreditorList
      ?.filter((item) => item.id === idCreditor)
      .map((item) => filteredCreditorsItems.push(item.operation_type_id))

    if (listAllOperationsType === null) return

    filteredCheckItems = listAllOperationsType
    setSelectCreditor(idCreditor)
    setListFiltered([...filteredCheckItems])
  }

  useEffect(() => {
    if (merchantsCreditorList && listAllOperationsType && idOperation) {
      filteredCreditor(idOperation)
    }
  }, [merchantsCreditorList, listAllOperationsType])

  const handleSelectCreditor = (handleChange: (prop: any) => void) => (item) => {
    handleChange({ target: { name: item.target.name, value: item.target.value.toString() } })
    filteredCreditor(item.target.value)
  }

  const creditorIdSchema = {
    creditor_id: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, Selecione uma opção`),
      },
    },
  }

  const schema = {
    first_installment_days_to_payment: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, Digite um numero correspondente a dias de repasse da primeira parcela`),
      },
    },
    n_installment_days_to_payment: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, Digite um numero correspondente a dias de repasse a demais parcelas`),
      },
    },
    matched_settlement: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, Selecione uma opção`),
      },
    },
    matched_mdr: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, Selecione uma opção`),
      },
    },
    checks_operations: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Por favor, Selecione uma opção`),
      },
    },
  }

  const handleSelect = (handleChange: (prop: any) => void) => (item) => {
    handleChange({ target: { name: item.target.name, value: item.target.value } })
  }

  return (
    <FormControl
      initialValue={initialFormState}
      validationSchema={merchantsCreditorList ? { ...schema, ...creditorIdSchema } : schema}
      onSubmit={handleSubmit}
      data-testid='mktCondiciones-newForm'
    >
      {(data) => {
        const { values, handleChange, errors } = data

        const renderSelectOperation = () => (
          <Box mx='1.6rem'>
            <Box textAlign={'center'} mt='1.9rem' data-testid='mktCondiciones-checkTitle-operation'>
              <Typography variant='h5' gutterBottom color='primary'>
                {i18n._(t`operationName`)}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {errors?.checks_operations && (
                <Grid item xs={12} data-testid='mktCondiciones-contentChek-operationError'>
                  <Typography variant='body1' color='error'>
                    {i18n._(t`selectAnOption`)}
                  </Typography>
                </Grid>
              )}

              {listAllOperationsType && (
                <ContainerStyled mt='1.25rem' width={1} data-testid='mktCondiciones-contentChek-operation'>
                  <ContentCheckMemo handleChange={handleChange} listFiltered={listFiltered} listChecks={list_checks} />
                </ContainerStyled>
              )}
            </Grid>
          </Box>
        )

        return (
          <Fragment>
            {merchantsCreditorList && (
              <Box mx='1rem' my='1.25rem'>
                <Grid container spacing={2}>
                  <Grid item container xs={12} md={12} spacing={2}>
                    <Grid item xs={12} data-testid='mktCondiciones-select-nome-credor'>
                      <TextField
                        label={i18n._(t`creditorName`)}
                        name='creditor_id'
                        onChange={handleSelectCreditor(handleChange)}
                        helperText={errors.creditor_id}
                        error={Boolean(errors.creditor_id)}
                        select
                      >
                        {merchantsCreditorList.map((item) => (
                          <MenuItem key={item.id} value={item.id} data-testid='mktCondiciones-option-nomeCredor'>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box mx='0.9rem' my='1.25rem'>
              <Grid container spacing={2}>
                <Grid item container xs={12} md={12} spacing={2}>
                  <Grid item xs={6} data-testid='mktCondiciones-input-taxaTransicao'>
                    <CurrencyInput
                      lang={language}
                      initialValue={values.transaction_fee}
                      TextfieldProps={{
                        label: i18n._(t`transitionFee`),
                        name: 'transaction_fee',
                      }}
                      onChange={(val) => {
                        handleChange({ target: { name: 'transaction_fee', value: val } })
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} data-testid='mktCondiciones-input-mdr'>
                    <PercentInput
                      TextfieldProps={{
                        label: i18n._(t`MDR`),
                        name: 'mdr',
                      }}
                      initialValue={values.mdr}
                      onChange={(val) => {
                        handleChange({ target: { name: 'mdr', value: val } })
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box mx='0.9rem' my='1.25rem'>
              <Grid container spacing={2}>
                <Grid item container xs={12} md={12} spacing={2}>
                  <Grid item xs={6} data-testid='mktCondiciones-input-repasseParcela'>
                    <TextField
                      label={i18n._(t`daysForRepaymentOfTheFirstInstallment`)}
                      name='first_installment_days_to_payment'
                      onChange={handleChange}
                      value={values.first_installment_days_to_payment}
                      helperText={errors.first_installment_days_to_payment}
                      error={Boolean(errors.first_installment_days_to_payment)}
                      mask
                      maskOptions={{ regex: '^[0-9]*$' }}
                    />
                  </Grid>
                  <Grid item xs={6} data-testid='mktCondiciones-input-repasseDemaisPacelas'>
                    <TextField
                      label={i18n._(t`transferDays`)}
                      name='n_installment_days_to_payment'
                      onChange={handleChange}
                      value={values.n_installment_days_to_payment}
                      helperText={errors.n_installment_days_to_payment}
                      error={Boolean(errors.n_installment_days_to_payment)}
                      mask
                      maskOptions={{ regex: '^[0-9]*$' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box mx='0.9rem' my='1.25rem'>
              <Grid container spacing={2}>
                <Grid item container xs={12} md={12} spacing={2}>
                  <Grid item xs={6} data-testid='mktCondiciones-select-taxaTransicao'>
                    <TextField
                      label={i18n._(t`transferFormOfTheTransaction`)}
                      name='matched_settlement'
                      onChange={handleSelect(handleChange)}
                      value={values.matched_settlement}
                      helperText={errors.matched_settlement}
                      error={Boolean(errors.matched_settlement)}
                      select
                    >
                      <MenuItem
                        key={'optionRepasseTransacao-0'}
                        value={0}
                        data-testid='mktCondiciones-repasseTransacao-options'
                      >
                        {i18n._(t`Integral`)}
                      </MenuItem>
                      <MenuItem
                        key={'optionRepasseTransacao-1'}
                        value={1}
                        data-testid='mktCondiciones-repasseTransacao-options'
                      >
                        {i18n._(t`installments`)}
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6} data-testid='mktCondiciones-select-repasseMDR'>
                    <TextField
                      label={i18n._(t`mdrTransferForm`)}
                      name='matched_mdr'
                      onChange={handleSelect(handleChange)}
                      value={values.matched_mdr}
                      helperText={errors.matched_mdr}
                      error={Boolean(errors.matched_mdr)}
                      select
                    >
                      <MenuItem key={'optionRepasseMdr-0'} value={0} data-testid='mktCondiciones-repasseMDR-options'>
                        {i18n._(t`Integral`)}
                      </MenuItem>
                      <MenuItem key={'optionRepasseMdr-1'} value={1} data-testid='mktCondiciones-repasseMDR-options'>
                        {i18n._(t`installments`)}
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box mx='0.9rem' my='1.25rem'>
              <Grid container spacing={2}>
                <Grid item container xs={12} md={12} spacing={2}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='card_not_present_allowed'
                          checked={values.card_not_present_allowed}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange({ target: { name: 'card_not_present_allowed', value: e.target.checked } })
                          }}
                        />
                      }
                      label={i18n._(t`allowCardlessTransactions`)}
                    />
                  </Grid>
                  <Grid item xs={6} data-testid='mkt-check-cpf'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='doc_number_allowed'
                          checked={values.doc_number_allowed}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange({ target: { name: 'doc_number_allowed', value: e.target.checked } })
                          }}
                        />
                      }
                      label={i18n._(t`allowTransactionsOnlyWithDoc`)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            {operationsTypesLoading ? (
              <Box my={2}>
                <Loading />
              </Box>
            ) : (
              renderSelectOperation()
            )}

            <Box width={1} display='flex' justifyContent='center' py='3.12rem'>
              <Box width='18.7rem' data-testid='mktCondiciones-button-salvar'>
                <Button
                  disabled={loading || operationsTypesLoading}
                  startIcon={loading ? <CircularProgress color='inherit' size={20} /> : null}
                  type='submit'
                  color='primary'
                  variant='contained'
                  fullWidth
                >
                  {i18n._(t`Salvar`)}
                </Button>
              </Box>
            </Box>
          </Fragment>
        )
      }}
    </FormControl>
  )
}

const ContentForm = memo(
  ConditionForm,
  (prev, next) =>
    prev.merchantsCreditorList === next.merchantsCreditorList &&
    prev.listAllOperationsType === next.listAllOperationsType &&
    Number(next.listAllOperationsType?.length) > 0,
)

const ConditionsNewForm: React.FC = () => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { errorSavingOperator, merchantsCreditorList, successOperator, loading, operationsTypesLoading } = useSelector(
    (state: RootState) => state.marketplaceConditions,
    shallowEqual,
  )
  const user = useSelector((state: RootState) => state.user)
  const [processingCodesList, setProcessingCodesList] = useState<any>([])

  const history = useHistory()
  const { id, idOperation } = useParams<any>()
  const dispatch = useDispatch()

  const [selectCreditor, setSelectCreditor] = useState<string>('')
  const { enqueueSnackbar } = useSnackbar()

  let list_checks: string[] = []

  const isValidMarketplace = !!merchantsCreditorList && !merchantsCreditorList?.length

  const {
    error,
    data,
    loading: isLoadingProcessingCodes,
  } = useQuery(GET_PROCESSING_CODES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  useEffect(() => {
    if (!!error) {
      enqueueSnackbar(i18n._(t`genericApiError`), {
        variant: 'error',
        onClose: () => dispatch(closeSnackbar()),
      })
    }
  }, [error])

  useEffect(() => {
    setProcessingCodesList(data?.search_processing_codes?.items)
  }, [data])

  useEffect(() => {
    dispatch(getMerchantsCreditorList(user, id))

    dispatch(closeSnackbar())
    return () => {
      dispatch(clearListAllOperationsType())
      dispatch(clearMerchantsCreditorList())
    }
  }, [])

  useEffect(() => {
    if (errorSavingOperator) {
      enqueueSnackbar(errorSavingOperator, {
        variant: 'error',
        onClose: () => dispatch(closeSnackbar()),
      })
    }

    if (successOperator) {
      enqueueSnackbar(i18n._(t`successfullyRegisteredData`), {
        variant: 'success',
        onClose: () => dispatch(closeSnackbar()),
      })
      dispatch(closeSnackbar())
      backToList()
    }
  }, [successOperator, errorSavingOperator])

  const backToList = () => {
    history.push(`/marketplace/edit/${id}/conditions`)
  }

  const handleSubmit = (values: ConditionType) => {
    let data = {
      card_not_present_allowed: values.card_not_present_allowed ? Boolean(values.card_not_present_allowed) : false,
      creditor_id: values.creditor_id,
      doc_number_allowed: values.doc_number_allowed ? Boolean(values.doc_number_allowed) : false,
      marketplace_id: parseInt(id),
      first_installment_days_to_payment: parseInt(values.first_installment_days_to_payment),
      matched_mdr: values.matched_mdr === 0 ? false : true,
      matched_settlement: values.matched_settlement === 0 ? false : true,
      mdr: Number(values.mdr),
      n_installment_days_to_payment: parseInt(values.n_installment_days_to_payment),
      transaction_fee: Number(values.transaction_fee),
    }

    dispatch(setOperations(id, values?.checks_operations, data, user))
  }

  const initialFormState = {
    creditor_id: selectCreditor ? selectCreditor.toString() : '',
    transaction_fee: '',
    mdr: '',
    first_installment_days_to_payment: '',
    n_installment_days_to_payment: '',
    matched_mdr: '',
    matched_settlement: '',
    card_not_present_allowed: '',
    doc_number_allowed: '',
    checks_operations: [],
  }

  const onBack = () => history.goBack()

  const renderMarketplaceMessageWithoutMerchants = () => (
    <Box display='flex' mt={2} alignItems='center'>
      <Typography sx={{ mr: 1 }}>{i18n._(t`mustHaveAtLeastOneEstablishmentLinked`)}</Typography>
      <Button onClick={onBack} variant='contained'>
        {i18n._(t`voltar`)}
      </Button>
    </Box>
  )

  return (
    <Box height={'90vh'}>
      <AppBar title={i18n._(t`operations`)} back={backToList}></AppBar>
      <PageContent>
        {!isValidMarketplace ? (
          <>
            <BackdropLoading loading={loading || isLoadingProcessingCodes} />
            <ContentForm
              initialFormState={initialFormState}
              merchantsCreditorList={merchantsCreditorList}
              handleSubmit={handleSubmit}
              listAllOperationsType={processingCodesList}
              list_checks={list_checks}
              idOperation={idOperation}
              operationsTypesLoading={operationsTypesLoading}
              setSelectCreditor={setSelectCreditor}
              loading={loading}
            ></ContentForm>
          </>
        ) : (
          renderMarketplaceMessageWithoutMerchants()
        )}
      </PageContent>
    </Box>
  )
}
export { ConditionsNewForm }
